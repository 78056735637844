<template>
  <div>
    <v-card-text>
      <!-- <v-card outlined class="white pa-2"> -->
      <v-tabs v-model="tab">
        <v-tabs-slider color="primary"></v-tabs-slider>
        <v-tab href="#comisiones" @click="tab = 'comisiones'">Comisiones</v-tab>
        <v-tab href="#calidad" v-if="validaPermiso('Dashboard Calidad Venta')"
          >Calidad de la venta</v-tab
        >
        <v-tab-item value="comisiones">
          <v-row>
            <v-col
              cols="12"
              sm="7"
              v-if="currentUser.AcessoDashBoard.BComisionPorProducto"
            >
              <evolucion-comision-por-producto
                :corteId="setCorteSelected"
                :tipoProducto="setTipoProductoSelected"
              ></evolucion-comision-por-producto>
            </v-col>
            <v-col
              cols="12"
              sm="5"
              v-if="currentUser.AcessoDashBoard.BDepositoBanco"
            >
              <deposito-en-banco
                :corteId="setCorteSelected"
                :tipoProducto="setTipoProductoSelected"
              ></deposito-en-banco>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              v-if="currentUser.AcessoDashBoard.BComisionesChargeback"
            >
              <comisiones-chargeback
                :corteId="setCorteSelected"
                :tipoProducto="setTipoProductoSelected"
              ></comisiones-chargeback>
            </v-col>
            <v-col
              cols="12"
              sm="7"
              v-if="currentUser.AcessoDashBoard.BComisionesGenerales"
            >
              <comisiones-generales
                :corteId="setCorteSelected"
                :tipoProducto="setTipoProductoSelected"
              ></comisiones-generales>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              sm="5"
              v-if="currentUser.AcessoDashBoard.BResidual"
            >
              <residual
                :corteId="setCorteSelected"
                :tipoProducto="setTipoProductoSelected"
              ></residual>
            </v-col>
            <v-col
              cols="12"
              sm="7"
              v-if="currentUser.AcessoDashBoard.BDetalleComision"
            >
              <detalle-comision
                :corteId="setCorteSelected"
                :tipoProducto="setTipoProductoSelected"
              ></detalle-comision>
            </v-col>
            <v-col
              cols="12"
              sm="5"
              v-if="currentUser.AcessoDashBoard.BDetalleComisionLineas"
            >
              <detalle-comision-lineas
                :corteId="setCorteSelected"
                :tipoProducto="setTipoProductoSelected"
              ></detalle-comision-lineas>
            </v-col>
            <v-col
              cols="12"
              sm="7"
              v-if="currentUser.AcessoDashBoard.BComisionPrepago"
            >
              <evolucion-comision-prepago
                :corteId="setCorteSelected"
                :tipoProducto="setTipoProductoSelected"
              ></evolucion-comision-prepago>
            </v-col>
            <v-col
              cols="12"
              sm="7"
              v-if="currentUser.AcessoDashBoard.BComisionPostpago"
            >
              <evolucion-comision-postpago
                :corteId="setCorteSelected"
                :tipoProducto="setTipoProductoSelected"
              ></evolucion-comision-postpago>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              sm="6"
              v-if="currentUser.AcessoDashBoard.BIncentivo"
            >
              <detalle-incentivo
                :corteId="setCorteSelected"
                :tipoProducto="setTipoProductoSelected"
              ></detalle-incentivo
            ></v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item value="calidad">
          <v-row>
            <v-col
              cols="12"
              sm="12"
              v-if="currentUser.AcessoDashBoard.BCalidadServicioMovil"
            >
              <calidad-servicio-movil
                :fechaFin="setPeriodoSelected"
              ></calidad-servicio-movil>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              v-if="currentUser.AcessoDashBoard.BCalidadServicioFijo"
            >
              <calidad-servicio-fijo
                :fechaFin="setPeriodoSelected"
              ></calidad-servicio-fijo>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              v-if="currentUser.AcessoDashBoard.BCalidadPrepago"
            >
              <calidad-prepago :fechaFin="setPeriodoSelected"></calidad-prepago>
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs>
      <!-- </v-card> -->
    </v-card-text>

    <v-fab-transition>
      <v-btn
        v-if="$vuetify.breakpoint.mobile"
        color="transparent"
        absolute
        top
        right
        fixed
        style="top: 120px; right: 0px"
        @click.native="drawerRight = !drawerRight"
      >
        <v-icon>filter_alt</v-icon>
      </v-btn>
    </v-fab-transition>

    <v-navigation-drawer v-model="drawerRight" app clipped fixed right>
      <v-card-text>
        <div class="subtitle-1 py-5">Filtros</div>
        <v-divider></v-divider>
        <br />
        <div v-if="tab == 'comisiones'">
          <v-row>
            <v-col cols="12">
              <v-select
                :items="corteOptions"
                v-model="corteSelected"
                item-text="fechas"
                item-value="CorteId"
                append-icon="keyboard_arrow_down"
                label="Periodo"
                persistent-hint
                hide-details
              ></v-select>
            </v-col>
            <br />
            <v-col cols="12">
              <v-autocomplete
                :items="tipoProductoOptions"
                v-model="tipoProductoSelected"
                item-text="ProductoName"
                item-value="ProductoTipoId"
                label="Tipos productos"
                hint="Dejar vacio para filtrar por todos"
                append-icon="keyboard_arrow_down"
                multiple
                chips
                deletable-chips
                persistent-hint
                clearable
              ></v-autocomplete>
            </v-col>
          </v-row>
        </div>
        <div v-if="tab == 'calidad'">
          <v-row>
            <v-col xs="12" sm="12">
              <v-select
                :items="periodoOptions"
                v-model="periodoSelected"
                item-text="NombreMes"
                item-value="FechaFin"
                append-icon="keyboard_arrow_down"
                hint="Filtrar por corte"
                hide-details
              ></v-select>
            </v-col>
          </v-row>
        </div>
        <br />
        <div class="mb-5 text-right">
          <v-btn block class="ma-1" depressed color="primary" @click="refresh">
            <v-icon left>search</v-icon>Buscar
            <span slot="loader">Buscando...</span>
          </v-btn>
        </div>
      </v-card-text>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  components: {
    EvolucionComisionPorProducto: () =>
      import("@/components/dashboards/evolucion_comision_tipo_producto"),
    DepositoEnBanco: () => import("@/components/dashboards/deposito_en_banco"),
    Residual: () => import("@/components/dashboards/residual_promedio"),
    DetalleComision: () =>
      import("@/components/dashboards/comision_x_componente"),
    DetalleIncentivo: () =>
      import("@/components/dashboards/comision_x_incentivo"),
    CalidadServicioMovil: () =>
      import("@/components/dashboards/calidad_servicio_movil"),
    CalidadServicioFijo: () =>
      import("@/components/dashboards/calidad_servicio_fijo"),
    CalidadPrepago: () => import("@/components/dashboards/calidad_prepago"),
    DetalleComisionLineas: () =>
      import("@/components/dashboards/detalle_comision_x_componente_lineas"),
    ComisionesGenerales: () =>
      import("@/components/dashboards/comisiones_generales"),
    ComisionesChargeback: () =>
      import("@/components/dashboards/comision_chargeback"),
    EvolucionComisionPrepago: () =>
      import("@/components/dashboards/evolucion_comision_prepago"),
    EvolucionComisionPostpago: () =>
      import("@/components/dashboards/evolucion_comision_postpago"),
  },
  data() {
    return {
      drawerRight: true,
      filter: true,
      tab: "comisiones",
      tipoProductoSelected: [],
      corteSelected: null,
      setCorteSelected: null,
      periodoSelected: null,
      setPeriodoSelected: null,
      setTipoProductoSelected: null,
      breadcrumb: [
        {
          text: "Dashboard",
          disabled: true,
        },
      ],
    };
  },
  computed: {
    corteOptions() {
      return this.$store.getters.cortes;
    },
    tipoProductoOptions() {
      return this.$store.getters.tipoProducto;
    },
    periodoOptions() {
      return this.$store.getters.periodos;
    },
    currentUser() {
      return this.$store.getters.user;
    },
  },
  methods: {
    ...mapActions(["setError", "setErrorMessage", "setMessage"]),
    refresh() {
      if (this.corteSelected || this.periodoSelected) {
        this.setPeriodoSelected = this.periodoSelected;
        this.setCorteSelected = this.corteSelected;
        this.setTipoProductoSelected = this.tipoProductoSelected;
      } else {
        let mensaje = {
          title: "Dashboards",
          body: "Favor seleccionar un corte para ver graficos",
          type: "success",
        };
        this.setMessage(mensaje);
      }
    },
    validaPermiso(accion) {
      return (
        this.currentUser.accesos.filter(
          (c) => c.title === accion && c.type === 6
        ).length > 0
      );
    },
  },
  mounted() {
    // this.$store.dispatch("setBreadcrumbs", this.breadcrumb);
    this.corteSelected = this.corteOptions[0].CorteId;
    this.periodoSelected = this.periodoOptions[0].FechaFin;
    this.refresh();
  },
};
</script>

<style scope>
.v-alert.v-sheet.theme--dark.v-alert--dense.warning {
  margin-bottom: 0;
}
.col-md-12.col-12 {
  padding: 0;
}
</style>